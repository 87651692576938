import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Navbar from '../components/navbar'
import Img from "gatsby-image"

class BookPage extends React.Component {
  render() {
    const siteTitle = "Sean Cheng"

    return (
      <Layout location={this.props.location} title={siteTitle}>  

      <SEO title="Books" />

      <h5 style={{textAlign:'center'}}> "The reading of all good books is like a conversation with the finest minds of past centuries." —Rene Descartes</h5>
      
        <details style={{textAlign:'center'}}>  
        <summary ><strong>2021</strong></summary>
        <ul>Winners Take All <br/><i>Anand Giridharadas</i></ul>
        <ul>The Almanack of Naval Ravikant<br/><i>Eric Jorgenson</i></ul>
        <ul>The Third Door<br/><i>Alex Banayan</i></ul>
        <ul>The Elements of Eloquence <br/><i>Mark Forsyth</i></ul>
        <ul>Amity and Prosperity: One Family and the Fracturing of America <br/><i>Eliza Griswold</i></ul>
        <ul>The Reason for God: Belief in an Age of Skepticism <br/><i>Timothy J. Keller</i></ul>
        
        </details>
        
        <details style={{textAlign:'center'}}>
        <summary ><strong>2020</strong></summary>
        <ul>Algorithms To Live By <br/><i>Brian Christian and Tom Griffiths</i> </ul>
        <ul>The Unfair Advantage: How You Already Have What It Takes to Succeed<br/><i>Ash Ali and Hassan Kubba</i></ul>
        <ul>When Breathe Becomes Air<br/><i>Paul Kalanithi</i></ul>
        <ul>The Greatness Guide <br/><i>Robin Sharma</i> </ul>
        <ul>Swipe To Unlock<br/><i>Aditya Agashe, Neel Mehta, and Parth Detroja</i></ul>
        <ul>Shoe Dog<br/><i>Phil Knight</i></ul>
        <ul>The Biggest Bluff: How I Learned to Pay Attention, Master Myself, and Win<br/><i>Maria Konnikova</i> </ul>
        <ul>The Cost of Discipleship<br/><i>Dietrich Bonhoeffer</i></ul>
        <ul>Man’s Search for Meaning<br/><i>Viktor Frankl</i> </ul>
        <ul>The Class Ceiling: Why It Pays To Be Privileged<br/><i>Daniel Laurison and Sam Friedman</i></ul>
        <ul>Evicted<br/><i>Matthew Desmond</i></ul>
        <ul>Barking Up the Wrong Tree<br/><i>Eric Barker</i></ul>
        <ul>Sapiens<br/><i>Yuval Noah Harari</i></ul>
        <ul>Inspired<br/><i>Marty Kagan</i></ul>
        <ul>Savage Inequalities<br/><i>Jonathan Kozol</i></ul>
        <ul>On Writing Well<br/><i>Willliam Zinsser</i></ul>
        <ul>Zero to One<br/><i>Peter Thiel</i></ul>
        <ul>The Prosperity Paradox<br/><i>Clayton Christensen, Efosa Ojomo, and Karen Dillon</i></ul>
        <ul>Range<br/><i>David Epstein</i></ul>
        <ul>Counterfeit Gods<br/><i>Timothy J. Keller</i></ul>
        <ul>Quarter Life Calling<br/><i>Paul Sohn</i></ul>
        </details>


        <details style={{textAlign:'center'}}>
        <summary ><strong>2019</strong></summary>
        <ul>Beginners Guide To Investing<br/><i>Alex Frey</i></ul>
        <ul>A Random Walk Down Wall Street<br/><i>Burton Malkiel</i></ul>
        <ul>Think and Grow Rich<br/><i>Napoleon Hill</i> </ul>
        <ul>One Up on Wall Street <br/><i>Peter Lynch</i></ul>
        <ul>Deep Work <br/><i>Cal Newport</i></ul>
        <ul>The Big Short<br/><i>Michael Lewis</i></ul>
        <ul>The Subtle Art of Not Giving A F*ck<br/><i>Mark Manson</i></ul>
        <ul>Purpose Driven Life<br/><i>Rick Warren</i></ul>
        <ul>A Mind For Numbers <br/><i>Barbara Oakley</i></ul>
        <ul>The Happiness Equation<br/><i>Neil Pasricha</i></ul>
        <ul>The Intelligent Investor<br/><i>Benjamin Graham</i></ul>
        <ul>Designing Your Life: How to Build a Well-Lived, Joyful Life<br/><i>Dave Evans and Bill Burnett</i></ul>
        <ul>12 Rules For Life<br/><i>Jordan Peterson</i></ul>
        <ul>Steal the Show<br/><i>Michael Port</i></ul>
        <ul>Culture Shock<br/><i>Chip Ingram</i></ul>
        <ul>Don't Waste Your Life<br/><i>John Piper</i></ul>
        <ul>The Little Book That Still Beats The Market<br/><i>Joel Greenblatt</i></ul>
        </details>
      </Layout>
    )
  }
}

export default BookPage